import React, { Component, useState, useEffect} from 'react';
import axiosInstance from '../AxiosInstance/axios';
import GreyBox from "../Components/Common/GreyBox";
import { styled } from '@mui/material/styles';
import {Paper, Stack} from "@mui/material";
import Diversity3Icon from '@mui/icons-material/Diversity3';
import CoffeeBreakHolder from '../Components/CoffeeBreak/CoffeeBreakHolder';
import CoffeeIcon from "@mui/icons-material/Coffee";
import { Link } from 'react-router-dom';
import EmailIcon from '@mui/icons-material/Email';
import LanguageIcon from '@mui/icons-material/Language';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import WorkIcon from '@mui/icons-material/Work';
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import "./UserDashboard.css";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: "inherit",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    boxShadow: "None",
    flex: "1", 
  }));

const UserDashboard = () => {
  const [open, setOpen] = useState(false);
  const [userDetails, setUserDetails] = useState({});

  useEffect(() => {
    fetchUserDetails();
  }, []);

  const handleClose = (optAnswer, reason ) => { 
    if (reason === 'backdropClick') {
        setOpen(true);
    } else {
        setOpen(false);
        if (optAnswer) {
            postOptAnswer(true);
        } else {
            postOptAnswer(false);
        }
    }
  };

  const fetchUserDetails = () => {
    axiosInstance.get("api/generic/userDetails")
    .then((response) => {
        setUserDetails(response.data);
        
        if (!response.data.askedForOptIn) {
          setOpen(true);
        }
    });
  };

  const postOptAnswer = (opt) => {
    axiosInstance.post("api/generic/optIn", {optIn: opt}, {
        headers:
            {'Content-Type': 'application/json'}}
    ).then((response) => {
        console.log(response.data)
    });
  } 

  return (
    <div>
        <GreyBox sx={{ width: '100%'}}>
            <Stack sx={{width: "50%", alignItems: "inherit", margin: "auto", padding: "60px"}}>
                <Item sx={{textAlign: "left"}}>
                    <h1>{userDetails.firstName} {userDetails.lastName}</h1>
                </Item>
                <Stack direction="row" spacing={8}>
                    <Item className='email-container'>
                        <div className='flex-container'>
                            <EmailIcon sx={{color:"#4DB0F4", marginTop: "8px", marginRight: "8px"}} fontSize="large" />
                            <p className='font'>{userDetails.email}</p>
                        </div>
                        <div className='flex-container'>
                            <LanguageIcon sx={{color:"#4DB0F4", marginTop: "8px", marginRight: "8px"}} fontSize="large" />
                            <p className='font'>{userDetails.country}</p>
                        </div>
                        <div className='flex-container'>
                            <LinkedInIcon sx={{color:"#4DB0F4", marginTop: "15px", marginRight: "8px"}} fontSize="large" /> 
                            <p className='font'><a href={userDetails.linkedIn}>{userDetails.linkedIn}</a></p>
                        </div>
                    </Item>
                    <Item className='email-container'>
                        <div className='flex-container'>
                            <WorkIcon sx={{color:"#4DB0F4", marginTop: "8px", marginRight: "8px"}} fontSize="large"/> 
                            <p className='font'>Discipline: {userDetails.profession}</p>
                        </div>
                        <div className='flex-container'>
                            <AssuredWorkloadIcon sx={{color:"#4DB0F4", marginTop: "8px", marginRight: "8px"}} fontSize="large" /> 
                            <p className='font'>Institution: {userDetails.workplace}</p>
                        </div>
                        <div className='flex-container'>
                            <MenuBookIcon sx={{color:"#4DB0F4", marginTop: "8px", marginRight: "8px"}} fontSize="large"/>
                            <p className='font'> Interests: {userDetails.interests === undefined ? "Interests Loading..." : userDetails.interests.join(', ')}</p>
                        </div>
                    </Item>
                </Stack>
            </Stack>
        </GreyBox>
        <Stack style={{padding: "50px"}}>
            <Item><h1>What service would you like to use?</h1></Item>
            <Item><h2 style={{fontWeight: "500"}}>Select the service you would like to apply to</h2></Item>
            <Stack direction="row" style={{marginTop: "20px"}} spacing={5}>
                <Item>
                    <Link to="/matchmaker">
                        <Diversity3Icon  sx={{ fontSize: 150, color: "#fcd21d", transition: "color 0.3s", 
                                            '&:hover': {color: "#4DB0F4"}
                                                }} />
                    </Link>
                    <h2>Multidisciplinary Matchmaker</h2>
                    <h3 className="description-2" style={{textAlign:'left'}}>Have a good idea for a research proposal or want to participate in one? Be connected with researchers across Scotland to help build a multi-disciplinary team targeting specific significant research calls.</h3>
                </Item>
                <Item>
                    <Link to="/coffeeBreaks">
                    <CoffeeIcon  sx={{ fontSize: 150, color: "#fcd21d", transition: "color 0.3s", 
                                            '&:hover': {color: "#4DB0F4"}
                                                }} />
                    </Link>
                    <h2>Coffee Breaks</h2>
                    <h3 className="description-2" style={{textAlign:'left'}}>Build your international network by scheduling short, no-commitment, informal coffee breaks over video calls with researchers across disciplines in Scotland and Germany.</h3>
                </Item>
            </Stack>
        </Stack>
        <GreyBox>
            
            <Stack style={{width:"100%"}} alignItems={"center"}>
                <Item>
                    <h1>Coffee Break Dashboard</h1>
                </Item>
                <Item>
                    <CoffeeBreakHolder/>
                </Item>
                    
            </Stack>
        </GreyBox>
      
        <Dialog open={open} onClose={handleClose}>
        <DialogTitle>User Notification Opt-In</DialogTitle>
        <DialogContent>
            Do you want to get notified when new matchmaker calls are available? You can change this setting in your profile settings.
        </DialogContent>
        <DialogActions>
        <Button onClick={() => {handleClose(true)}} color="primary">
                Yes
            </Button>
            <Button onClick={() => {handleClose(false)}} color="primary">
                No
            </Button>
        </DialogActions>
        </Dialog>
    </div>
  );
};

export default UserDashboard;