import React, {useEffect, useState} from "react";
import {
    Alert,
    AlertTitle,
    Autocomplete,
    Button,
    Card,
    Checkbox, Chip,
    FormControlLabel, Grid,
    Stack,
    TextField,
    Box
} from "@mui/material";
import {Link} from "react-router-dom";
import isEmail from 'validator/lib/isEmail';
import isUrl from "is-url";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Intro_Image from "../Images/hero_image.png";
import "./Register.css";
import axiosInstance from "../AxiosInstance/axios";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: "inherit",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    flexGrow: 1,
    border: "none",
    boxShadow: "none"
  }));

  const Item2 = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    boxShadow: "None",
    flex: "1", 
  }));

const Register = ({isLoggedIn}) => {
    const [formDetails, setFormDetails] = useState({email: null, password: null, firstName: null, lastName: null, linkedIn: null, productInterests: [], researchPool: null, interests: null, profession: null, workplace: null, role: null})
    const [isNotInInScotland, setIsNotInInScotland] = useState(false)

    const [emailAgain, setEmailAgain] = useState('')
    const [passwordAgain, setPasswordAgain] = useState('')
    const [errors, setErrors] = useState([])
    const [userCreationIsSuccess, setUserCreationIsSuccess] = useState(0)  //0: not created, 1: failed, 2: success

    const [professions, setProfessions] = useState([])
    const [roles, setRoles] = useState([])
    const [interests, setInterests] = useState([])
    const [researchPools, setResearchPools] = useState([])
    const [universities, setUniversities] = useState([])
    useEffect(()=>{
        if(isLoggedIn){
            window.location.href="/"
        }
        window.scrollTo(0, 0)
        getDropdownValues("api/details/getProfessions", setProfessions, "professions")
        getDropdownValues("api/details/getInterests", setInterests, "interests")
        getDropdownValues("api/details/getPools", setResearchPools, "pools")
        getDropdownValues("api/details/getUniversities", setUniversities, "unis")
        getDropdownValues("api/details/getRoles", setRoles, "roles")
    }, [])

    const lazyPoolAcronymQuickFixDict = {
        "SAGES": "SAGES (Scottish Alliance for Geoscience, Environment, and Society)",
        "Brain Health Arc": "Brain Health Arc",
        "SCAF": "SCAF (Scottish Alliance for Food)",
        "ETP": "ETP (Energy Technology Partnership)",
        "SRPE": "SRPE (Scottish Research Partnership in Engineering)",
        "SINAPSE": "SINAPSE (Scottish Imaging Network: A Platform for Scientific Excellence)",
        "MASTS": "MASTS (Marine Alliance for Science and Technology Scotland)",
        "ScotChem": "ScotChem",
        "SICSA": "SICSA (Scottish Informatics and Computer Science Alliance)",
        "SULSA": "SULSA (Scottish Universities Life Sciences Alliance)",
        "SUPA": "Scottish Universities Physics Alliance (SUPA)",
        "BE-ST": "Built Environment - Smarter Transformation (BE-ST)",
        "Quantum Technology ARC": "Quantum Technology ARC"
    }


    function getDropdownValues(endpoint, setterFunction, key){
    axiosInstance.get(endpoint)
      .then(response => {
        // Handle the response data here
        setterFunction(response.data[key])
      })
      .catch(error => {
        // Handle errors here
        console.error('Error in Register.js: ', error);
      });
}

    async function createAccount() {
        setErrors([])
        let isValidEmail = formDetails.email && isEmail(formDetails.email) && isEduEmail(formDetails.email)
        let isMatchingEmail = formDetails.email && (formDetails.email === emailAgain)
        let isMatchingPassword = formDetails.password && (formDetails.password === passwordAgain) && formDetails.password.length > 7
        let isFirstName = formDetails.firstName && formDetails.firstName.length > 0
        let isLastName = formDetails.lastName && formDetails.lastName.length > 0
        let isLinkedIn = formDetails.linkedIn=="" || formDetails.linkedIn==null || isUrl(formDetails.linkedIn)
        let isResearchPool = (formDetails.researchPool && !isNotInInScotland) || isNotInInScotland
        let isInterests = formDetails.interests && formDetails.interests.length>0
        let isProfession = formDetails.profession
        let isWorkplace = formDetails.workplace
        let isRole = formDetails.role
        if (isValidEmail && isMatchingEmail && isMatchingPassword && isFirstName && isLastName && isLinkedIn && isResearchPool && isInterests && isProfession) {
            await sendCreateAccountRequest()
        } else {
            let tempErrors = []
            if (!isValidEmail) {
                tempErrors.push("Email given is not valid or not an educational email address.")
            }
            if (!isMatchingEmail) {
                tempErrors.push("Emails entered don't match")
            }
            if (!isMatchingPassword) {
                if (!formDetails.password || formDetails.password.length <= 7) {
                    tempErrors.push("Password must be at least 8 characters long")
                }
                if (formDetails.password !== passwordAgain) {
                    tempErrors.push("Passwords given don't match")
                }
            }
            if (!isFirstName) {
                tempErrors.push("First name must be given")
            }
            if (!isLastName) {
                tempErrors.push("Last name must be given")
            }
            if(!isLinkedIn){
                tempErrors.push("LinkedIn must be either left as empty or the URL to your linkedIn profile")
            }
            if(!isProfession){
                tempErrors.push("Discipline must be selected!")
            }
            if(!isInterests){
                tempErrors.push("At least three areas of expertise must be selected!")
            }
            if(!isWorkplace){
                tempErrors.push("Your workplace must be given")
            }
            if(!isResearchPool){
                tempErrors.push("You must either select that you are not based in Scotland/don't have a research pool, or select a research pool you are associated with.")
            }
            if(!isRole){
                tempErrors.push("You must select your role!")
            }
            setErrors(tempErrors)
        }
    }

    function isEduEmail(email) {
        let emailDomain = email.split('@')[1].toLowerCase()

        // Check if email is an Scottish educational email
        if (emailDomain.endsWith(".ac.uk")) {
            return true
        }

        // Check if email is a German educational email
        const data = require('../util/Germany-Universities.json')

        for (let i = 0; i < data.length; i++) {
            if (emailDomain.endsWith(data[i].domains)) {
                return true
            }
        }

        return false
    }

    async function validateEmailAddress() {
        let user = {email: formDetails.email, password: formDetails.password, first_name: formDetails.firstName, last_name: formDetails.lastName}
        await axiosInstance.post('/api/activate/',
            user, {
                headers:
                    {'Content-Type': 'application/json'},
            }).then(response => {
                let respData = response.response.data
                handleError(respData)
            },
            error => {
                handleError(error.response.data)
            }
        ) 
    }

    async function sendCreateAccountRequest() {
        let cleanedData = cleanData(formDetails)
        await axiosInstance.post('/api/register/',
            cleanedData, {
                headers:
                    {'Content-Type': 'application/json'},
            }).then(response => {
                if (response.status >= 200 && response.status < 300) {
                    setUserCreationIsSuccess(2)
                } else {
                    let respData = response.response.data
                    handleError(respData)
                }
            },
            error => {
                handleError(error.response.data)
            }
        )
    }

    function cleanData(data){
        function comboboxValuesToString(value){
            if(value){
                return value.label;
            }else{
                return ""
            }
        }

        let cleanedData = JSON.parse(JSON.stringify(data))
        cleanedData.profession = comboboxValuesToString(data.profession)
        cleanedData.workplace = comboboxValuesToString(data.workplace)
        cleanedData.researchPool = comboboxValuesToString(data.researchPool)
        cleanedData.role = comboboxValuesToString(data.role)
        cleanedData.email = cleanedData.email.toLowerCase()
        return cleanedData
    }

    function handleError(respData) {
        let curErrors = []
        if (respData.email) {
            curErrors.push(respData.email)
        }
        if (respData.errors) {
            curErrors = [...curErrors, ...respData.errors]
        }
        setErrors(curErrors)
    }

    function updateFormState(key, value){
        setFormDetails({...formDetails, [key]:value})
    }

    function handleproductInterestsCheck(name, isChecked){
        if(!isChecked){
            const newState = formDetails.productInterests.filter((item) => item !== name);
            updateFormState("productInterests", [...newState])
        }else if(!formDetails.productInterests.includes(name)){
            updateFormState("productInterests", [...formDetails.productInterests, name])
        }
    }


    return (
        <>
            <Stack direction="row" spacing={5} sx={{padding: "50px", backgroundColor: "#F5F5F5"}}>
                <Item2 style={{textAlign: "right", backgroundColor: "#F5F5F5"}}><img src={Intro_Image} height={"300px"} width={"450px"} alt="home-page1"/></Item2>
                <Item2 style={{textAlign: "left", backgroundColor: "#F5F5F5"}}>
                    <h2>Matchmaker & Coffee Breaks</h2>
                    <p style={{width: "50%"}}>
                    Interested in building your multidisciplinary network through short and informal online conversations with fellow researchers? Or do you have a good research idea for a research proposal, but need to connect with other researchers to make it great? The Research and Innovation Scotland network of partners wants to help - try our Coffee Breaks and Matchmaker schemes.
                    </p>        
                </Item2>
            </Stack>
            <Box style={{margin: "auto", maxWidth: "450px"}}>
                <Card variant="outlined" className="register" justify="center">
                    <div>
                        {userCreationIsSuccess < 2 &&
                            <div>
                                <h2>Register</h2>
                                <h4 style={{fontWeight: "400"}}>Sign up for our services today.</h4>
                                <Alert severity="info" sx={{marginBottom: "10px", textAlign: "left"}}>
                                    <AlertTitle>Info</AlertTitle>
                                    <p style={{alignItems: "justify"}}>Please ensure that you register with your work/university email.</p>
                                </Alert>
                                {errors.length > 0 &&
                                    <Alert severity="error" style={{textAlign: "left"}}>
                                        <AlertTitle>Error</AlertTitle>
                                        The following errors occured while trying to create the account:
                                        <ul>
                                            {errors.map((error, index) => (
                                                <li key={index}>{error}</li>
                                            ))}
                                        </ul>
                                    </Alert>
                                }
                                <Stack className="register-form" spacing={{xs: 1}}>
                                    <Item className="label">Email:*</Item>
                                    <TextField required type={"email"} style={{margin: "20px 0px 0px 0px"}}
                                               onChange={e => {updateFormState("email", e.target.value)}}></TextField>
                                    <Item className="label">Confirm E-mail:*</Item>
                                    <TextField required type={"email"}
                                               onChange={e => setEmailAgain(e.target.value)}></TextField>
                                    <Item className="label">First Name:*</Item>
                                    <TextField required type={"text"}
                                               onChange={e => {updateFormState("firstName", e.target.value)}}></TextField>
                                    <Item className="label">Last Name:*</Item>
                                    <TextField required type={"text"}
                                               onChange={e => {updateFormState("lastName", e.target.value)}}></TextField>
                                    <Item className="label">Password:*</Item>
                                    <TextField required type={"password"}
                                               onChange={e => {updateFormState("password", e.target.value)}}></TextField>
                                    <Item className="label">Confirm Password:*</Item>
                                    <TextField required type={"password"}
                                               onChange={e => setPasswordAgain(e.target.value)}></TextField><br/>
                                    <Item className="label">LinkedIn (URL):</Item>
                                    <TextField type={"url"}
                                               onChange={e => {updateFormState("linkedIn", e.target.value)}}></TextField><br/>
                                    <Item className="label">Institution:</Item>
                                    <Autocomplete
                                        id="autocomplete-unis"
                                        options={universities}
                                        groupBy={(option)=>option.category}
                                        getOptionLabel={(option) => option.label}
                                        filterSelectedOptions
                                        onChange={(event, newValue)=>{updateFormState("workplace", newValue)}}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                          />
                                        )}
                                      />
                                    <Item className="label">Which research network are you most closely aligned with?</Item>
                                    <Autocomplete
                                        disablePortal
                                        id="autocomplete-role"
                                        options={researchPools}
                                        disabled={isNotInInScotland}
                                        key={isNotInInScotland}
                                        getOptionLabel={(option) =>
                                            typeof option === 'string'
                                            ? lazyPoolAcronymQuickFixDict[option] || option
                                            : option.label || ''
                                        }
                                        onChange={(event, newValue) => {
                                            updateFormState("researchPool", newValue);
                                        }}
                                        renderInput={(params) => <TextField {...params} label="Research Network" />}
                                    />

                                    <FormControlLabel control={<Checkbox onChange={e=>{updateFormState("researchPool", null);
                                                                                        setIsNotInInScotland(!isNotInInScotland)}} checked={isNotInInScotland}/>} label="I am not associated with a research network." />

                                    <Item className="label">Provide 3-5 (press enter to seperate) keywords that best describe your expertise:</Item>
                                    <Autocomplete
                                        multiple
                                        id="autocomplete-interests"
                                        options={[]}
                                        freeSolo={formDetails.interests?(formDetails.interests.length > 4 ? false : true):true}
                                        onChange={(event, newValue)=>{updateFormState("interests", newValue)}}
                                        renderTags={(value, getTagProps) =>
                                          value.map((option, index) => (
                                            <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                          ))
                                        }
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            label="Type up to 5 terms:"
                                            placeholder={formDetails.interests
                                                        ? `${5-formDetails.interests.length} remaining`
                                                        : '5 remaining'}
                                          />
                                        )}
                                      />
                                    <Item className="label">Discipline:</Item>
                                    <Autocomplete
                                      disablePortal
                                      id="autocomplete-profession"
                                      options={professions}
                                      onChange={(event, newValue)=>{updateFormState("profession", newValue)}}
                                      renderInput={(params) => <TextField {...params} />}
                                    />
                                    <Item className="label">Role:</Item>
                                    <Autocomplete
                                      disablePortal
                                      id="autocomplete-role"
                                      options={roles}
                                      onChange={(event, newValue)=>{updateFormState("role", newValue)}}
                                      renderInput={(params) => <TextField {...params} />}
                                    />

                                    <div>
                                        <div className="label-checkbox">Which service would you be interested in:</div>
                                        <Grid container spacing={3}>
                                            <Grid item xs={6}>
                                                <FormControlLabel control={<Checkbox onChange={(e)=>handleproductInterestsCheck("MatchMaker", e.target.checked)}/>} label="MatchMaker" />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <FormControlLabel control={<Checkbox onChange={(e)=>handleproductInterestsCheck("CoffeeBreak", e.target.checked)}/>} label="CoffeeBreak" />
                                            </Grid>
                                        </Grid>
                                    </div>
                                    <div style={{textAlign: "right"}}>
                                        <Button variant={"outlined"} sx={{color: "#333333", fontWeight: "500", fontSize:"16px", width: "30%", textTransform: "none", margin: "15px 0px 10px 0px"}}  onClick={() => {
                                            createAccount()
                                        }}>Register</Button>
                                    </div>
                                </Stack>
                            </div>
                        }
                        <Stack sx={{margin: "auto", alignItems: "center"}}>
                            {userCreationIsSuccess === 2 && 
                            <Item>
                                <h4>User Created, please verify your account through 
                                the email sent to your registered email address. Make sure to check your junk box.</h4>
                            </Item>}
                            <Item>
                                <div style={{margin: "20px 0px 20px 0px"}}>
                                    <Link to={"/login"} style={{color: "#4DB0F4", fontSize: "16px"}}>Already have an account? Log in</Link>
                                </div>
                            </Item>
                        </Stack>
                    </div>
                </Card>
            </Box>
        </>
    );
}

export default Register;