import React, { Component, useEffect, useState } from 'react';
import axiosInstance from '../../AxiosInstance/axios';
import GreyBox from '../Common/GreyBox';
import {Box, Paper, Stack, Button, RadioGroup, Radio, FormControl, FormControlLabel, FormLabel, Zoom, TextField,
    Alert, AlertTitle, LinearProgress, Checkbox, Autocomplete, FormHelperText, Select, MenuItem } from "@mui/material";
import { Link } from 'react-router-dom';
import Item from '../Common/Item';
import { useParams } from 'react-router-dom';


const  MatchMakerSubmissionForm = () => {

    const { title } = useParams();

    const [form, setForm] = useState("");
    const [expertise, setExpertise] = useState("");
    const [description, setDescription] = useState("");
    const [proposalTitle, setProposalTitle] = useState("");
    const [success, setSuccess] = useState(0);
    const [errors, setErrors] = useState([]);
    //const [poolKeywords, setPoolKeywords] = useState([])
    //const [pool, setPool] = useState([])
    const [principalInvestigator, setPrincipalInvestigator] = useState("");
    const [highLevelDetail, setHighLevelDetail] = useState("");
    const [expertiseList, setExpertiseList] = useState([]);
    const [selectedExpertise, setSelectedExpertise] = useState([]);
    const [expertPathway, setExpertPathway] = useState(false);

/*
    useEffect(()=>{
        getPoolKeywordValues("api/register/getPoolsForCall/" + title, setPoolKeywords);
    },[title])
    async function getPoolKeywordValues(endpoint, setterFunction){
        await axiosInstance.get(endpoint)
          .then(response => {
            // Handle the response data here
            console.log("RESPOPNSE POOL WOOERDS:") // check this
            console.log(response) // check this
            setterFunction(response.data.pools)
          })
          .catch(error => {
            // Handle errors here
            console.error('Error:', error);
          });
    }
*/
    useEffect(()=>{
        console.log("Title: ", title)
        let encodedTitle = encodeURIComponent(title);
        axiosInstance.get("api/register/getExpertiseForCall/" + encodedTitle)
            .then(response => {
                setExpertiseList(response.data.expertise);
                setExpertPathway(response.data.expertpathway);
                console.log("RESPONSE EXPERTISE:" , response.data.expertpathway)
            })
            .catch(error => {
                console.error('Error:', error);
            });
    },[title]);

    async function sendNoIdeaData(){
        let expertiseInput = expertise.length > 0;
        let descriptionInput = description.length > 0;
        if (expertiseInput && descriptionInput) {
            setSuccess(1)
            const formDetails = {title: title, description: description, expertise: expertise}
            await axiosInstance.post('/api/register/registerExpertiseForCall', formDetails , {
                headers:
                    {'Content-Type': 'application/json'},
            }).then(response => {
                if (response.status === 200) {
                    setSuccess(2)
                } else {
                    let respData = response.response.data
                    handleError(respData)
                }
            },
            error => {
                handleError(error.response.data)
            }
        ) 
        } else {
            let tempErrors = [];
            if (!expertiseInput) {
                tempErrors.push("The Expertise description is empty!")
            }
            if (!descriptionInput){
                tempErrors.push("The details section is empty!")
            }
            setErrors(tempErrors);
        }
    }

    async function sendYesIdeaData() {
        let principalInvestigatorInput = principalInvestigator !== "";
        let highLevelDetailInput = highLevelDetail.length > 0;
        let selectedValuesInput = selectedExpertise.length > 0;

        if (principalInvestigatorInput && highLevelDetailInput && selectedValuesInput) {
            
            const formDetails = {title: title, proposalTitle: proposalTitle, principalInvestigator: principalInvestigator, description: highLevelDetail, expertise: selectedExpertise}
            setSuccess(1)
            console.log("formDetails: ", formDetails)
            console.log("success: ", success)

            await axiosInstance.post('/api/register/registerIdeaForCall', formDetails , {
                headers:
                    {'Content-Type': 'application/json'},
            }).then(response => {
                if (response.status === 200) {
                    setSuccess(2)
                } else {
                    let respData = response.response.data
                    handleError(respData)
                }
            },
            error => {
                handleError(error.response.data)
            }
        ) 
        } else {
            let tempErrors = [];
            if (!principalInvestigatorInput) {
                tempErrors.push("The Principal Investigator is not selected!")
            }
            if (!highLevelDetailInput){
                tempErrors.push("The details section is empty!")
            }
            if (!selectedValuesInput){
                tempErrors.push("The expertise gaps are not chosen!")
            }
            setErrors(tempErrors);
        }
    }

    function handleError(respData) {
        let curErrors = []
        if (respData.email) {
            curErrors.push(respData.email)
        }
        if (respData.errors) {
            curErrors = [...curErrors, ...respData.errors]
        }
        setErrors(curErrors)
    }


      const poolsNames = {
        "SAGES": "Scottish Alliance for Geoscience, Environment & Society (SAGES)",
        "Brain Health Arc": "Brain Health ARC Alliance",
        "SCAF": "The Scottish Alliance for Food (SCAF)",
        "ETP": "Scottish Research Partnership for Energy (ETP)",
        "SRPE": "Scottish Research Partnership in Engineering (SRPE)",
        "SINAPSE": "Scottish Imaging Network: A platform for Scientific Excellence (SINAPSE)",
        "MASTS": "Marine Alliance for Science and Technology for Scotland (MASTS)",
        "ScotChem": "Scottish Universities Chemistry Research Pool (ScotChem)",
        "SICSA" : "Scottish Informatics & Computer Science Alliance (SICSA)",
        "SULSA": "Scottish Universities Life Sciences Alliance (SULSA)",
        'SUPA': 'Scottish Universities Physics Alliance (SUPA)',
        'IBIOLC': 'Industrial Biotechnology Innovation Centre (IBIOLC)',
        'CENSIS': 'Sensor and Imaging Systems Innovation Centre (CENSIS)',
        "SUPA": "Scottish Universities Physics Alliance (SUPA)",
        "BE-ST": "Built Environment - Smarter Transformation (BE-ST)",
        "Quantum Technology ARC": "Quantum Technology ARC"
    };

    return (
        <>
            <GreyBox>
                <Box sx={{maxWidth: "700px", margin: "auto"}}>
                    <Stack>
                        <Item>
                            <h1>Multidisciplinary Matchmaker Application</h1>
                        </Item>
                        {success === 0 &&
                        <>
                        <Item>
                            <h4 style={{textAlign: "left", fontWeight: 400}}>Matchmaker leverages the collective networks of the Scottish Research Pools and Innovation Centres to connect potential collaborators on research proposals. We will use the information you provide to find potential collaborators for you, by consulting with these partners. By completing the form, you consent to our using the information for this purpose.</h4>
                        </Item>
                        <Item>
                            <Button
                                key={"matchmakerform"}
                                size="small"
                                variant="outlined"
                                sx={{my: 3, width: "25%", textTransform: 'none', padding:"6px 9px", color: '#878787', fontFamily: "sans-serif", fontWeight:"600", display: 'block',}}
                                component={Link} to="/matchmaker"
                            >
                                Previous
                            </Button>
                        </Item>
                        <Item>
                            <FormControl>
                                <FormLabel required id="demo-row-radio-buttons-group-label">Do you have a research project idea that aligns with the call?</FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    value={form}
                                    onChange={(e) => {setForm(e.target.value)}}
                                    sx={{display: "flex"}}
                                >
                                    <FormControlLabel value="Yes" style={{flex:1}} control={<Radio />} label="Yes" />
                                    <FormControlLabel value="No" control={<Radio />} label="No" />
                                </RadioGroup>
                            </FormControl>
                        </Item>
                        </>
                        }
                        {form === "No" && success === 0 && (
                            <Item>
                                <Zoom in={true}>
                                    <div style={{margin: "auto", maxWidth: "700px"}}>
                                        {errors.length > 0 &&
                                            <Alert severity="error" style={{textAlign: "left"}}>
                                                <AlertTitle>Error</AlertTitle>
                                                The following errors occured while submit the form:
                                                <ul>
                                                    {errors.map((error, index) => (
                                                        <li key={index}>{error}</li>
                                                    ))}
                                                </ul>
                                            </Alert>
                                        }
                                        {expertPathway &&
                                            <Stack spacing={2}>
                                                <FormControl sx={{alignItems:"flex-start"}}>
                                                    <FormLabel required id="demo-row-radio-buttons-group-label" sx={{marginBottom: "5px"}}>Provide a short description of your expertise:</FormLabel>
                                                    <TextField 
                                                    label={"Expertise"} 
                                                    type={"text"} 
                                                    multiline
                                                    rows={4}
                                                    onChange={event =>{setExpertise(event.target.value)}} 
                                                    sx={{ width: "100%"}}/>
                                                </FormControl>
                                                <br/>
                                                <FormControl sx={{alignItems:"flex-start"}}>
                                                    <FormLabel required id="demo-row-radio-buttons-group-label" sx={{marginBottom: "5px"}}>Provide details of how you believe this expertise will contribute to a proposal for this call:</FormLabel>
                                                    <TextField 
                                                    label={"Description"} 
                                                    type={"text"}
                                                    multiline
                                                    rows={4}
                                                    onChange={event => {setDescription(event.target.value)}} 
                                                    sx={{ width: "100%"}}/>
                                                </FormControl>
                                                <br/>
                                                <div style={{display: "flex", justifyContent: "center", }}>
                                                    <Button
                                                        size="small"
                                                        variant="outlined"
                                                        sx={{my: 3, width: "25%", textTransform: 'none', padding:"6px 9px", color: '#878787', fontFamily: "sans-serif", fontWeight:"600", display: 'block',}}
                                                        onClick={e => {sendNoIdeaData()}}
                                                    >
                                                        Submit Interest
                                                    </Button>
                                                </div>
                                            </Stack>
                                        }  
                                        
                                        {!expertPathway &&   
                                            <Stack spacing={2}>
                                                <FormControl sx={{alignItems:"flex-start"}}>
                                                    <br/>
                                                    <FormLabel id="demo-row-radio-buttons-group-label" sx={{marginBottom: "5px"}}><b>Sorry this call does not support Experts. Contact us if you think this is an error, otherwise return to the Matchmaker Calls page</b></FormLabel>
                                                    <br/>
                                                    <div style={{display: "flex", justifyContent: "center" }}>
                                                        <Button
                                                            size="small"
                                                            variant="outlined"
                                                            sx={{my: 3, width: "100%", textTransform: 'none', padding:"6px 9px", color: '#878787', fontFamily: "sans-serif", fontWeight:"600", display: 'block',}}
                                                        >
                                                            <Link to="/matchmaker">Return to Matchmaker Calls</Link>
                                                        </Button>
                                                    </div>
                                                    
                                                </FormControl>
                                            </Stack>
                                        }
                                    </div>
                                </Zoom>
                            </Item>
                        )}

                        {form === "Yes" && success === 0 && (
                        <>               
                            {errors.length > 0 &&
                                <Alert severity="error" style={{textAlign: "left"}}>
                                    <AlertTitle>Error</AlertTitle>
                                    The following errors occured while submit the form:
                                    <ul>
                                        {errors.map((error, index) => (
                                            <li key={index}>{error}</li>
                                        ))}
                                    </ul>
                                </Alert>
                            }         
                            <Item>
                                <FormControl>
                                    <FormLabel required id="demo-row-radio-buttons-group-label">Please confirm whether you are eligible to act as a Principal Investigator:</FormLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        value={principalInvestigator}
                                        onChange={(e)=> {setPrincipalInvestigator(e.target.value)}}
                                        sx={{display: "flex"}}
                                    >
                                        <FormControlLabel value="Yes" style={{flex:1}} control={<Radio />} label="Yes" />
                                        <FormControlLabel value="No" control={<Radio />} label="No" />
                                    </RadioGroup>
                                </FormControl>
                            </Item>
                            <Item>
                                <FormControl sx={{alignItems:"flex-start", width: "600px"}}>
                                    <FormLabel required id="demo-row-radio-buttons-group-label" sx={{marginBottom: "5px"}}>What is the working title of your proposal?</FormLabel>
                                    <TextField 
                                    label={"Title"} 
                                    type={"text"}
                                    multiline
                                    rows={2}
                                    onChange={event => {setProposalTitle(event.target.value)}} 
                                    sx={{ width: "100%"}}/>
                                </FormControl>
                                <br/>
                            </Item>
                            <Item>
                                <FormControl sx={{alignItems:"flex-start", width: "600px"}}>
                                    <FormLabel required id="demo-row-radio-buttons-group-label" sx={{marginBottom: "5px"}}>Please provide high level details of your project idea:</FormLabel>
                                    <FormHelperText>Be aware that we may share aspects of the proposed idea within the RIS network to help identify potential collaborators for you.</FormHelperText>
                                    <TextField 
                                    label={"Description"} 
                                    type={"text"}
                                    multiline
                                    rows={4}
                                    onChange={event => {setHighLevelDetail(event.target.value)}} 
                                    sx={{ width: "100%"}}/>
                                </FormControl>
                                <br/>
                            </Item>
                            <Item>
				                <FormControl sx={{alignItems: "flex-start", alignItems: "center", width: "600px"}}>
                                    <FormLabel required>Which expertise gap are you trying to fill in? (Select all that apply)</FormLabel>
                                    <Select
                                        multiple
                                        value={selectedExpertise}
                                        onChange={event => setSelectedExpertise(event.target.value)}
                                        sx={{width: "100%"}}
                                    >
                                        {expertiseList.map((expertise, index) => (
                                            <MenuItem key={index} value={expertise}>
                                                {expertise}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Item>
                            <Item>
                                <div style={{display: "flex", justifyContent: "center", }}>
                                    <Button
                                        size="small"
                                        variant="outlined"
                                        sx={{my: 3, width: "25%", textTransform: 'none', padding:"6px 9px", color: '#878787', fontFamily: "sans-serif", fontWeight:"600", display: 'block',}}
                                        onClick={e => {sendYesIdeaData()}}
                                    >
                                        Submit Interest
                                    </Button>
                                </div>
                            </Item>
                        </>
                        )}
                        {success === 1 &&
                            <div>
                                <LinearProgress/>
                            </div>
                        }
                        {success === 2 &&
                        <>
                            <Item>
                                <h3>Thank you for your submission. We will be in touch!</h3>
                            </Item>
                            <Item sx={{ display: "flex", justifyContent: "center",}}>
                                <Button
                                    key={"matchmakerform"}
                                    size="small"
                                    variant="outlined"
                                    sx={{my: 3, width: "25%", textTransform: 'none', display: "flex", justifyContent: "center", padding:"6px 9px", color: '#878787', fontFamily: "sans-serif", fontWeight:"600", display: 'block',}}
                                    component={Link} to="/userdashboard"
                                >
                                    Go Back
                                </Button>
                            </Item>
                        </>
                        }                      
                    </Stack>
                </Box>
            </GreyBox>
        </>
    )
}

export default MatchMakerSubmissionForm;