import React, {useEffect, useState} from "react";
import {
    Card, 
    Stack,
    Grid,
    Button,
    CardContent,
    Typography,
    Divider
} from "@mui/material";
import axiosInstance from "../../AxiosInstance/axios";
import { Link } from "react-router-dom"; 
import GreyBox from "../Common/GreyBox";
import Item from "../Common/Item";
import Cb1Img from "../../Images/matchmakerhomepage.png"
import styled, { keyframes } from 'styled-components';

// Animation for already registered button
const jiggle = keyframes`
    0% { transform: translateX(0px) rotate(0deg); }
    25% { transform: translateX(-4px) rotate(-2deg); }
    50% { transform: translateX(4px) rotate(2deg); }
    75% { transform: translateX(-4px) rotate(-2deg); }
    100% { transform: translateX(0px) rotate(0deg); }
`

const JiggleDiv = styled.div`
    animation: 0.2s ${jiggle} linear;
`

const MatchMakerProposals = () => {

    const [proposals, setProposals] = useState([])
    const [isClicked, setIsClicked] = useState(false)
    const [showFullDescription, setShowFullDescription] = useState(new Array(proposals?.length).fill(false));

    const handleClick = () => {
        setIsClicked(true);
        setTimeout(() => { setIsClicked(false) }, 200);
    };

    useEffect(()=>{
        getProposalValues()
        window.scrollTo(0, 0)
    }, [])

    function getProposalValues() {
        axiosInstance.get("api/register/details/GetCallsWithInterest")
          .then(response => {
            setProposals(response.data.calls)
          })
          .catch(error => {
            console.error('Error:', error);
          });
    }

    function formatClosingDate(date) {
        let formattedDate = ""
        let splitDate = date.split("-")
        formattedDate = splitDate[2] + "/" + splitDate[1] + "/" + splitDate[0]
        return formattedDate
    }

    return (
        <div>
            <GreyBox sx={{ width: '100%'}}>
                <Stack direction="row" spacing={5} style={{padding: "50px"}}>
                    <Item style={{textAlign: "right"}}><img src={Cb1Img} className="Intro_Image" height={"300px"} width={"450px"} alt="home-page1"/></Item>
                    <Item style={{textAlign: "left"}}>
                        <h2>Multidisciplinary Matchmaker</h2>
                        <h3 style={{width: "50%"}}>Connect with researchers from outside your discipline.</h3>
                        <p style={{width: "50%"}}>
                        Do you have a good research idea but need to connect with researchers from outside your discipline and outside your university to make it great? The Scottish Research Pools and Partners want to help.
                        </p>        
                    </Item>
                </Stack>
            </GreyBox>

            <Grid container direction="column" alignItems="center" justifyContent="center" >
                <Typography><h2>Current Research Calls</h2></Typography>
                <Typography>Browse our open research calls and register your interest.</Typography>
                
            </Grid>

            <br/>

            <Divider></Divider>

            <Stack spacing={1}>
                {proposals?.map((proposal, index) => (
                    
                    <Card key={index} sx={{ display: 'flex', flexDirection: 'column'}}> {/* alignItems: 'center', justifyContent: 'center' */}
                        <CardContent sx={{ textAlign: 'center' }}>

                            <Typography><h2>{proposal.title}</h2></Typography>

                            <Typography variant="body1">
                                <span style={{ marginRight: '20px' }}> <b>Supporting Networks:</b> {proposal.expertise} </span> 
                                <span style={{ marginRight: '20px' }}> <b>Funding Amount:</b> {proposal.funding_amount} </span>
                                <span> <b>Closing Date:</b> {formatClosingDate(proposal.closing_date)} </span>
                            </Typography>

                            <Typography variant="body1" sx={{ marginTop: 2 }} style={{textAlign: showFullDescription[index] ? "left" : "center"}}> 
                                <b>Description: </b> 
                                {showFullDescription[index] ? proposal.description : `${proposal.description.substring(0, 120)}... `}
                                {proposal.description.length > 120 && (
                                    <a href="#" onClick={(e) => {
                                        e.preventDefault();
                                        const newShowFullDescription = [...showFullDescription];
                                        newShowFullDescription[index] = !newShowFullDescription[index];
                                        setShowFullDescription(newShowFullDescription);
                                    }}>
                                        {showFullDescription[index] ? "..Less" : "More"}
                                    </a>
                                )}
                            </Typography>
                            
                            <Typography variant="body1" sx={{ marginTop: 1, alignItems: 'center' }}>
                                <span style={{ marginRight: '20px' }}> <b>Funder:</b> {proposal.funder === "" ? "N/A" : proposal.funder} </span>
                            </Typography>

                            <Typography variant="body1" sx={{ marginTop: 1 }}>
                                <b>Link:</b> {proposal.link === "" ? "N/A" : <a href={/^http/.test(proposal.link) ? proposal.link : `http://${proposal.link}`} target="_blank" rel="noopener noreferrer"> {proposal.link} </a>}
                            </Typography>

                            <br/>

                            {/* Register button depending if user has registered interest for the call: */}

                                {/* If user has not already registered for this call then allow them to by displaying a Register button */}
                                {!proposal.registered &&(
                                        <Button
                                            key={"matchmakerform"}
                                            size="small"
                                            variant="outlined"
                                            style={{backgroundColor: '#32cd32'}}
                                            sx={{my: 3, width:"50%", textTransform: 'none', padding:"6px 9px", color: 'white', fontFamily: "sans-serif", fontWeight:"600", margin: "auto"}}
                                            component={Link} to={{pathname: `/matchmakerform/${proposal.title}`, state: { title1: "bla" },}}
                                            onClick={e => {localStorage.setItem("title", proposal.title)}}
                                        >
                                            Register Your Interest
                                        </Button>
                                )}

                                {/* If Already Registered to a proposal display button with Already Registered name and don't allow the user to proceed with a jiggle animation */}
                                {proposal.registered &&(
                                    <div>
                                        {isClicked ? (
                                            <JiggleDiv> 
                                                <Button 
                                                    variant="contained" 
                                                    size="small"
                                                    onClick={handleClick}
                                                    style={{backgroundColor: '#878787'}}
                                                    sx={{my: 3, width:"50%", textTransform: 'none', padding:"6px 9px", color: 'white', fontFamily: "sans-serif", fontWeight:"600", margin: "auto"}}
                                                >
                                                    Already Registered
                                                </Button>
                                            </JiggleDiv>
                                        ) : (
                                            <Button 
                                                variant="contained" 
                                                size="small"
                                                onClick={handleClick}
                                                style={{backgroundColor: '#878787'}}
                                                sx={{my: 3, width:"50%", textTransform: 'none', padding:"6px 9px", color: 'white', fontFamily: "sans-serif", fontWeight:"600", margin: "auto"}}
                                            >
                                                Already Registered
                                            </Button>
                                        )}
                                    </div>
                                )}  
                        </CardContent>
                    </Card>
                ))}
                <Typography style={{padding:"10px"}}><h3>Are you looking to be matched with an expert in your application for a funding call that isn't listed here? Please get in touch via the {<a href="https://www.research-innovation-scotland.co.uk/contact-us/">Contact Us Here</a>}</h3></Typography> 
            </Stack>
        </div>
    );
}

export default MatchMakerProposals;